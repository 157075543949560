import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`Portfolio Review 2023`}</h1>
    <Breadcrumbs text="Articles" to="/articles/" mdxType="Breadcrumbs" />
    <h2>{`Overview`}</h2>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/review-2021/"
          }}>{`Review 2021`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/review-2022/"
          }}>{`Review 2022`}</a></li>
      </ul>
    </Aside>
    <p>{`Investing is about money, and with 2023 in the books it is time to review the performance of our portfolios. Overall, 2023 was another challenging year for investors. Those who held on to their S&P 500 shares were highly rewarded for their bravery, reaping gains of 26.3%. However, the year was everything but a smooth ride, doing its best to throw off tactical algorithms.`}</p>
    <p>{`Here is the performance of the `}<a parentName="p" {...{
        "href": "https://finance.yahoo.com/quote/%5EGSPC"
      }}>{`S&P 500`}</a>{` in broad strokes:`}</p>
    <ul>
      <li parentName="ul">{`12/01/2022: 4,100.51`}</li>
      <li parentName="ul">{`12/22/2022: 3,765.49 (-8.1%)`}</li>
      <li parentName="ul">{`02/02/2023: 4,195.44 (+11.4%)`}</li>
      <li parentName="ul">{`03/13/2023: 3,808.86 (-9.2%)`}</li>
      <li parentName="ul">{`07/27/2023: 4,607.07 (+21.0%)`}</li>
      <li parentName="ul">{`10/27/2023: 4,103.78 (-10.9%)`}</li>
      <li parentName="ul">{`12/29/2023: 4,788.43 (+16.7%)`}</li>
    </ul>
    <p>{`It is easy to see how these fast reversals make it hard for trend-following and momentum strategies to stay on the right side of the market. As these strategies have to observe the market before they can react, they are always a tad behind the market, realizing some losses when markets turn down, and missing some of the following rebound.`}</p>
    <p>{`To successfully navigate these market swings, a trading strategy needs to separate momentum from volatility. A while ago, we wrote a `}<a parentName="p" {...{
        "href": "/articles/low-noise-momentum/"
      }}>{`background article about Round Robin's inner workings`}</a>{`, which explains the methods used to reduce the inherent noise and make better investment choices. However, none of the algorithms are perfect, and some members have noticed that `}<a parentName="p" {...{
        "href": "/portfolios/tt-round-robin/"
      }}>{`Round Robin`}</a>{` was holding onto `}<a parentName="p" {...{
        "href": "https://finance.yahoo.com/quote/XLE"
      }}>{`XLE`}</a>{` longer than discretionary traders felt comfortable with and missing out on better opportunities in Q4/2023.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e1eb161fb47bdd2b63ed360b2ea4d72d/21b4d/all-stars-xl-cumulative.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACWUlEQVQoz42ST0hUURTGb5siahPkokUZBS3atG7fzoKCAquFLVyUixIElchoVRBEUOmiTbUNhdTEQEMNqY3EjOPM4Myoo8P4Z3zj/Hnvzbx3373vF+/NlLXrgw/OOffc7957vis+LuQ40DFD+9Aql16scaInyZGuOMe7k5zuT9Pan+JUb4qTTbb2LXPsfoILTzKce5impTvJ4a44He8z4EtE0bYZmM7wY7PAjuXydMLg2lCOy69yiOtRxO0YoiPgEqIzjmhf4vzjFeayBpOpAm2v1zn6IE4kXwEtESgNSAxVwsYCfECzVja5+iZH54dNzvRnaOlJhKK9n3KkSqVwDzhkqnvMZncJUKmaCCkl2g9zNhyDXVmh6tXZksVGEZeEUWZmfZtHk2t41PDx8HXjbOlLJE4Yl8tlhCslnvZR2sfTmo26QcGtYnoOe26NfL3MjreHwkPjoBR4AcN+P8wDSk/hOA4CX/Ev9vM8+fBZ/4tQcHXXYuRnhfl0ja9Ji9FIlbFIlc9Rk/F4kamEydyyxdyyzbeUxVTcYjxiMhkzmVg0w76xiMn3lSpSuojkdoV7wxneLeQZiRbpGy7Q/jbHlcEs4k6Sg3eTiFsxxM1FxI0oh7qWuPg8zeD8Fi9nN2kbXOHsQILRmIFbtxGBow1oPCxU6LRDDZP4tk1sp8KXlMFIrMCz6RzZstV0+DcCSxpjCU2x7RqO6+G6HtJVlOoW1XpQkxiegevX0MpuimiCmUvphesBg1h5CivQCWaolML3/T/8jSD0m5ev193QxeDL6r969xk4rdBa8wumEgeI/IwGtAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "all stars xl cumulative",
            "title": "all stars xl cumulative",
            "src": "/static/e1eb161fb47bdd2b63ed360b2ea4d72d/c1b63/all-stars-xl-cumulative.png",
            "srcSet": ["/static/e1eb161fb47bdd2b63ed360b2ea4d72d/5a46d/all-stars-xl-cumulative.png 300w", "/static/e1eb161fb47bdd2b63ed360b2ea4d72d/0a47e/all-stars-xl-cumulative.png 600w", "/static/e1eb161fb47bdd2b63ed360b2ea4d72d/c1b63/all-stars-xl-cumulative.png 1200w", "/static/e1eb161fb47bdd2b63ed360b2ea4d72d/21b4d/all-stars-xl-cumulative.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`All-Stars XL`}</h2>
    <p>{`Regardless of how much effort we put into our strategies, it is important to realize that no single trading algorithm works under all circumstances. That's why TuringTrader focuses on its `}<a parentName="p" {...{
        "href": "/portfolios/?tag=meta#filter"
      }}>{`family of All-Stars portfolios`}</a>{`. These portfolios combine multiple strategies with different characteristics, aiming to cover a broader range of macro-economic scenarios. In this family, `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-xl/"
      }}>{`All-Stars XL`}</a>{` is the flagship, combining six component strategies to a balanced portfolio. Because this portfolio combines various trading styles (momentum, mean-reversion, volatility-targeting), it serves as a proxy for our overall performance.`}</p>
    <p>{`Looking at 2023, `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-xl/"
      }}>{`All-Stars XL`}</a>{` underperformed the 60/40 benchmark. While XL gained about 10.7%, the benchmark rose up by 17.7%. However, looking at the two-year performance paints a very different picture. While the strategy ultimately did not manage to escape its benchmark over this difficult period, it did so with significantly lower volatility and downside. Investors working toward critical financial goals, e.g., their retirement, should keep in mind that reducing volatility is ultimately more important than achieving the highest return. We have written much more about the `}<a parentName="p" {...{
        "href": "https://www.invest-like-a-pro.com/basics/investment-scenarios/"
      }}>{`relationship between returns, volatility, and financial goals`}</a>{` in our book.`}</p>
    <p>{`We are very proud of the strategy's performance, and it did exactly what it was designed to do. It protected investors from undue risk, and it nicely picked up on the market upswing in Q4/2023. Because the portfolio has, during bullish markets, a higher exposure to the stock market, we are confident that it will outperform the balanced benchmark in the long run. We hope that 2024 will be one of those years. In any case, we believe that investors should be happy with their results - even if they trailed the benchmark in the past year.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/35e322e6a777a200ebefd713657ff64a/21b4d/buoy-cumulative.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB8klEQVQoz4WTy04UQRSG+1V8Ap/IN9DELSti4saVK5ezNQgERKIIaiYhbGBGhoAIBrkIYYa50NNV1V23ru7PTHMRyCSe5M+pU/9J1fnPqYqESsl1AqUGSv5v5Zi4pLbapz1IiY4HKXNbCTMNyXRT3EdDMPtdMNMUzG9KPu0oNv8Y5luSqYao/MaxpnVqeT7VRmpH1E4yXtVPeTp9wuT7AZMLPV4uDnj95ZLaWszHbcnEXJfHLw55NHHAwYWjtjbkTf2SpR1F80QzSENVq5KSiCJcl26BG9mjdXFPWF/lHPUdEMY2wgRPPIyJnPf4UOLykkubcW4S+jalZxU2L1DecaZj4lwRB0nXSS6spG0EfatwIWAKT+wU3vm7FY65FUHB3WEVDzJyMhRJKaEssNYSdYVmZV+w0JJ82PqHzzspSz8TVn9Lvu5mt3uHfUd9P2V5V9E4Mux1LD3l0TZgzOhAqVnYHVBb7/CuOWRuUzDbGrK4LajvadYPDVMbgmdvOzypnXMWe77tpSz/UGwcaX51HHFakLqcNMseSraV0Ct/M5hwG3sMluwOfzWkHE9WGESSEGVaY52/Ro62rvLGebo6IbaKRClSY9DO4n1e5RrnUFYTG0VsJcYYvPdEIYSq52VZVqjefnn/N3jnKIpiLHdjI36Ev5ZLPDgKmJXOAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "buoy cumulative",
            "title": "buoy cumulative",
            "src": "/static/35e322e6a777a200ebefd713657ff64a/c1b63/buoy-cumulative.png",
            "srcSet": ["/static/35e322e6a777a200ebefd713657ff64a/5a46d/buoy-cumulative.png 300w", "/static/35e322e6a777a200ebefd713657ff64a/0a47e/buoy-cumulative.png 600w", "/static/35e322e6a777a200ebefd713657ff64a/c1b63/buoy-cumulative.png 1200w", "/static/35e322e6a777a200ebefd713657ff64a/21b4d/buoy-cumulative.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Buoy`}</h2>
    <p><a parentName="p" {...{
        "href": "/portfolios/tt-buoy/"
      }}>{`Buoy`}</a>{` acts as the stabilizing component in `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-xl/"
      }}>{`All-Stars XL`}</a>{`. This strategy once more outperformed its benchmark - by a lot. Where the `}<a parentName="p" {...{
        "href": "https://finance.yahoo.com/quote/AGG"
      }}>{`aggregate bond market`}</a>{` gained about 5.7%, `}<a parentName="p" {...{
        "href": "/portfolios/tt-buoy/"
      }}>{`Buoy`}</a>{` made about 10.9%. And all this by, most of the year, investing in `}<a parentName="p" {...{
        "href": "https://finance.yahoo.com/quote/BIL"
      }}>{`T-Bills`}</a>{` with virtually no risk. What sticks out here is that the strategy rotated into commercial bonds in November 2023, continuing its lead over the aggregate bond market. This shows how `}<a parentName="p" {...{
        "href": "/portfolios/tt-buoy/"
      }}>{`Buoy`}</a>{` can significantly reduce interest-rate, without missing out on higher yielding opportunities. With these properties, we believe `}<a parentName="p" {...{
        "href": "/portfolios/tt-buoy/"
      }}>{`Buoy`}</a>{` to be among the very best managed bond strategies available, suitable for income-oriented portfolios, to tame aggressive investments, and as a risk-off strategy alike.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e3d5cff2e89c36666706c7ff7d8f7dec/21b4d/all-stars-stocks-cumulative.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACgElEQVQoz32RO2iTURiGv6Fe0MnBDo6Cix0FVwcRFxfFQcW2Vq3aIgqtFBTUSZxsVbBYWxR0Ei8oUotWbXqB2Kb3JCZpbEt6SS9p/jSX/89/fySJRV184eE757zD9x5e6Q6u0PQiyAffMi8HUzR/XuVpf5L2viQd/Sk6+pW/pkJ7v0Kbp+AnijzxKLR6EjwfiqPldeTZ4AJSN0JXZI27n5apuDWNHJtAKgNIlR+p3CBQmqeDSLUfOTuE1HqR2hGkaoTdN0Jkc3lkJZ2nczZG3FoD8rwbzXKweZYD934iZ4Jsqw+z5VKYzfVBNl0MsfXyJFLvY2fjD8obIpRdGUbqfFTcnCaZSiO2aePioDkGc0aCuJYGLN5H55HzIeTcCFI7itR/p+yqD6nxcrRthplVi8n5PLffrxYTH3kYI5dZRwzTxHJccGHd1IjlEyzraTK2xmv/Cg+8s+y/P86eWyGkbpiaZ4skMjYb0k2H1h6F+aSObeoI/DELclEBs3hOES+mLUg1HMZjBv+TrhtIeCnL29EMwUWdvimVzsks30IqX0M5ukIKnkiu+D4QVRma1egJqfRGNHqjWTzRTNHrieQYiqUxTRPpDic49DjAx+gSjwbiHG6ZQ84FkAt+5HgAOeFHThVanvjd8hhy0odUD5eoGkRODFJ+LYiynkFwHErftrDJEVMVgol1OqeW2Xc3wt47k+xoGmd7wxhyyceu634a3yzg/anhCau0fEmw9/YUTa9WyKRTiKppmKaNZVoYhkXazGK5+eKSuL6KkleZmEvijabpnEixmNSBjRAlspoJjo5hGIht27iuW4JS264Lzu/mi3Is/lzAdtx/KMi0bBzX5Rfgi9QoHm1j0QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "all stars stocks cumulative",
            "title": "all stars stocks cumulative",
            "src": "/static/e3d5cff2e89c36666706c7ff7d8f7dec/c1b63/all-stars-stocks-cumulative.png",
            "srcSet": ["/static/e3d5cff2e89c36666706c7ff7d8f7dec/5a46d/all-stars-stocks-cumulative.png 300w", "/static/e3d5cff2e89c36666706c7ff7d8f7dec/0a47e/all-stars-stocks-cumulative.png 600w", "/static/e3d5cff2e89c36666706c7ff7d8f7dec/c1b63/all-stars-stocks-cumulative.png 1200w", "/static/e3d5cff2e89c36666706c7ff7d8f7dec/21b4d/all-stars-stocks-cumulative.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`All-Stars Stocks`}</h2>
    <p>{`On the aggressive end of the spectrum, things weren't quite so rosy. The chart above shows how the combination of `}<a parentName="p" {...{
        "href": "/portfolios/tt-stocks-on-the-loose/"
      }}>{`Stocks on the Loose`}</a>{` and `}<a parentName="p" {...{
        "href": "/portfolios/tt-round-robin/"
      }}>{`Round Robin`}</a>{` did a great job reducing risk throughout 2022. Further, `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-stocks/"
      }}>{`All-Stars Stocks`}</a>{` extracted good profits during Q2, while at the same time reducing volatility. However, in Q4, the portfolio missed out, leaving money on the table. In summary, the portfolio earned 6.6%, while its benchmark gained 26.3%.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e92609a518448a7b30066b95d463e8a7/21b4d/comparison-cumulative.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACRElEQVQoz32RXWsTQRSG9795If4CvRP/gdcFwUuh3om1iBUUkV5YKBVFrTa2UFtLWy2NMaZNmjTfySa7O/s1Mzs7jySp2Bt9h5cznA8473mdoRdw0qxROj+l5faIdIzQIZGJAH2JlhlydK6IjSQxEmUkGYpRGpGkKc6vcpXb82s8Wi6wtN7k/gefhcKI+Xcu1x/XufG0zpUHp1x7WOPqQpWbz+vce9/nsOZzdBaytD1mbrXHQcklUwpHRgGd78eE5Qqq10B4KcFI0qz67FQ1u8WIlc0xK4Uhd1802PvmMm4KbBBig4iwE9Ou9LBpgghDnMxkMyWpJOueoTtV8m4F3HPodyHwIRlA7kMeAgaQWOtj7RBsMD2JzXL8sYejtcaYnHzSGiuy/oDMF2gRkLR/opRA6QQpBsi4gYqGZMLFiBDjBWTeEB2M0IGL0grH2px/wkhIQogFkIGOwEqw+m9Ppmb5yd5S4rhuwJeyS6Uz5mSQsN+K2a5FbJxGrB54rB16rB1HF4x5fRyxW09xRU5zlPG1nvK2GFPvhphM47jDIVufjyjulSgVy+z86PPpoMmb7QaLWwOebPa486rK3HKNW4sVnn1ss3HYo9HqUKm2WN/v8LJwRr09Jk3iS5In0e/DqAl+B6IBDKuQjkF5oAcg+0AwM0h6ICe1PpjRVLY3MSVOEpTSKJ2hjEVJNf1LlZF4PtIXiJFHIiJk6JEKgQxDVKqmczKMSYOIJEmYGOwYY7CTZy84WfZPvLi7UorcWv6HPM+n/A1cwTbzYQknngAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "comparison cumulative",
            "title": "comparison cumulative",
            "src": "/static/e92609a518448a7b30066b95d463e8a7/c1b63/comparison-cumulative.png",
            "srcSet": ["/static/e92609a518448a7b30066b95d463e8a7/5a46d/comparison-cumulative.png 300w", "/static/e92609a518448a7b30066b95d463e8a7/0a47e/comparison-cumulative.png 600w", "/static/e92609a518448a7b30066b95d463e8a7/c1b63/comparison-cumulative.png 1200w", "/static/e92609a518448a7b30066b95d463e8a7/21b4d/comparison-cumulative.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Comparison to Other Strategies`}</h2>
    <p>{`Overall, we believe `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-xl/"
      }}>{`All-Stars XL`}</a>{` faired quite well, given a year with multiple market reversals. To prove that this is not just a lame excuse, we compare our `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-xl/"
      }}>{`All-Stars XL`}</a>{` portfolio against some of our competition. The chart above shows that `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-xl/"
      }}>{`All-Stars XL`}</a>{` performed admirably against its competitors. In particular:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/portfolios/keller-bold-asset-allocation/"
        }}>{`Keller's Bold Asset Allocation`}</a>{` is the only strategy beating `}<a parentName="li" {...{
          "href": "/portfolios/tt-all-stars-xl/"
        }}>{`All-Stars XL`}</a>{` over the past two years. However, it is worth noting that `}<a parentName="li" {...{
          "href": "/portfolios/keller-bold-asset-allocatino/"
        }}>{`Keller's strategy`}</a>{` takes almost the entirety of its edge from March 2022. Throughout the remainder of the period, the strategy trails `}<a parentName="li" {...{
          "href": "/portfolios/tt-all-stars-xl/"
        }}>{`All-Stars XL`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/portfolios/li-universal-investment-strategy/"
        }}>{`Logical Invest's Universal Investment Strategy`}</a>{` did fairly well, with a performance closely tracking the 60/40 benchmark. However, this high correlation raises questions about the effectiveness of the strategy.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/portfolios/livingston-mama-bear/"
        }}>{`Livingston's Mama Bear`}</a>{` did quite well in 2022, but ended 2023 flat, severely underperforming the 60/40 benchmark.`}</li>
      <li parentName="ul"><strong parentName="li">{`Scott Juds' SectorSurfer ETF`}</strong>{` (WIZ) suffered tremendously in 2022 and was unable to recover in 2023. This is most likely why the ETF was delisted in November 2023. We are sorry to see it go - after all our `}<a parentName="li" {...{
          "href": "/articles/low-noise-momentum/"
        }}>{`Round Robin was heavily inspired`}</a>{` by Juds' SectorSurfer strategies.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/portfolios/ep-accelerating-dual-momentum/"
        }}>{`Engineered Portfolio's Accelerating Dual Momentum`}</a>{` trails the field, mostly due to steep losses in 2022. This makes sense, as the strategy relies on long-term Treasuries as its risk-off asset, which was an unfortunate choice in 2022. The strategy's 2023 performance actually comes close to the benchmark, which is a great result.`}</li>
    </ul>
    <h2>{`Live Trading vs. Backtests`}</h2>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/live-trading-vs-backtests/"
          }}>{`Live trading vs. backtesting`}</a></li>
      </ul>
    </Aside>
    <p>{`Simulations are good, but ultimately, we need to confirm that these backtests track live trading with reasonable accuracy. To do so, we compare our backtests against a six-figure account we are trading. Over the observed period, this account has been invested in `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-tr/"
      }}>{`All-Stars Total Return`}</a>{` (v2, v3), and `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-xl/"
      }}>{`All-Stars XL`}</a>{` (v1, v2). Also, the were a number of withdrawals from that account. We stitched together the various backtests to reflect the strategy changes, and we adjusted the account values for the withdrawals.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/65c85ea070565a1ff551639312d1b6dd/21b4d/tracking-detail.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABvklEQVQoz31T22oUQRCd//8CRRBfFF9EffDBIN5ilAUvISSublwV3CXZOO7s9P1WfaRqnM0awQNFV/dUn1NVXdMsWoNfnYI1GqWUv4zYqCKVCgYRbc934yoROhOhjUMzXVqoMFyIpWLwAJcqqAKFKmwkIa4VchZzRaYKHQj5j1hnM3pt0cxXDiYQqFb0rmDjCmImKF8kkLP7qTNCGmJYwCcSstdzi8MfHi4RepdhXECjXMSXiyhEZ5ssxgQvZxaLLonI8TLIGQu3OuN7m8Suv1jj2vO1kLY6YaMsmhAT9k4MTlcRT6cGB6dWSPZONPZnFpOvDg8PlezvTDZ4fKxxY3+NR0caN191uD3Z4Nkng7ffLLqRkMlm5wFv5hZHC4/pWZASGbzeOuhw712PJx8N7r9XePBBiRBXsOqzZHh30kEZjyamIf3P50EIuDRuAfdqBJfMlxmtKVLB+BjcV8aS26MNGh8SLlSB9mVLojxtR4VR5IXH0bn0eR19nwqMdWhcSOg9bQMYrE47l0ZcJdolZCHnHBpmtT4h54yUkhj7455XDowx/jcmhDAQ8qRfqv+bgQx8jPKXXM14d8/f2X4DXX1P+nHbAwcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "tracking detail",
            "title": "tracking detail",
            "src": "/static/65c85ea070565a1ff551639312d1b6dd/c1b63/tracking-detail.png",
            "srcSet": ["/static/65c85ea070565a1ff551639312d1b6dd/5a46d/tracking-detail.png 300w", "/static/65c85ea070565a1ff551639312d1b6dd/0a47e/tracking-detail.png 600w", "/static/65c85ea070565a1ff551639312d1b6dd/c1b63/tracking-detail.png 1200w", "/static/65c85ea070565a1ff551639312d1b6dd/21b4d/tracking-detail.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The chart above shows how live trading is tracking the backtests. We can see that TuringTrader's backtests very accurately represent the performance achieved in live trading. The tracking curve shows some daily fluctuations, of about one percent, but overall remains flat, illustrating that our assumptions about fills and commissions are realistic. The majority of the static error was incurred in Q1/2022 and is most likely related to changes in the account's strategy.`}</p>
    <h2>{`Summary`}</h2>
    <p>{`Overall, we believe that this comparison shows that TuringTrader's `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-xl/"
      }}>{`All-Stars XL`}</a>{` is a best-in-class portfolio, offering tangible advantages over comparable strategies. Further, we believe that our portfolios will continue to outperform, especially when market environments return to more predictable sentiment. And finally, our backtests accurately reflect live trading performance, allowing our members to invest with great confidence.`}</p>
    <br />
    <ButtonPrimary text="see our portfolios" to="/portfolios/" mdxType="ButtonPrimary" />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      